import type { UseComboboxReturnValue, UseMultipleSelectionReturnValue } from 'downshift'
import { Fragment, useRef } from 'react'
import { IconButton, XIcon } from '@qasa/qds-ui'
import { styled } from '@qasa/ui/web'

import { VisuallyHidden } from '../../visually-hidden'

import type { OptionToLabel } from './autocomplete'
import { AutocompleteSelectedItems } from './autocomplete-selected-items'

const OUTER_HEIGHT = 56

const StyledIconButton = styled(IconButton)({
  backgroundColor: 'transparent',
})

const AutocompleteTextInput = styled('input')(({ theme }) => ({
  backgroundColor: 'inherit',
  border: 'none',
  paddingLeft: theme.spacing['3x'],
  paddingBottom: 0,
  paddingTop: 0,
  flex: 1,
  minWidth: theme.sizes[224],
  height: 'inherit',
  borderRadius: 'inherit',
  '::placeholder': {
    color: theme.colors.text.default,
  },
  margin: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
  ...theme.typography.label.md,
  ':focus': {
    outline: 'none',
  },
}))

export const AutocompleteBox = styled('div')<{ hasError?: boolean; isGray?: boolean }>(
  ({ theme, hasError, isGray }) => ({
    backgroundColor: hasError ? theme.colors.core.yellow10 : theme.colors.core.white,
    width: '100%',
    minHeight: OUTER_HEIGHT,
    border: '1px solid',
    borderColor: hasError ? theme.colors.border.negative : theme.colors.border.default,
    borderRadius: theme.radii.full,
    paddingLeft: theme.spacing['1x'],
    paddingRight: theme.spacing['2x'],
    overflowY: 'hidden',
    display: 'flex',
    alignItems: 'center',
    transitionProperty: 'border-color, box-shadow',
    transitionDuration: '120ms',
    ':focus-within': {
      borderColor: theme.colors.border.defaultSelected,
      boxShadow: `inset 0 0 0 1px ${theme.colors.border.defaultSelected}`,
    },
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    ...(isGray && {
      backgroundColor: theme.colors.bg.brandTertiary,
      borderColor: theme.colors.border.subtle,
      ':focus-within': {
        backgroundColor: 'white',
        borderColor: theme.colors.border.defaultSelected,
        boxShadow: `inset 0 0 0 1px ${theme.colors.border.defaultSelected}`,
      },
      ':hover:not(:focus-within)': {
        backgroundColor: theme.colors.bg.brandTertiaryHover,
      },
    }),
  }),
)

const AutocompleteInputGrid = styled('div')({
  flex: 1,
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
  height: 'inherit',
})

type ClearButtonProps = {
  onClick: () => void
}
export function ClearButton({ onClick }: ClearButtonProps) {
  return <StyledIconButton icon={XIcon} size={'sm'} onClick={onClick} label={'Clear'} />
}

type AutocompleteInputProps<TOption> = {
  placeholder?: string
  optionToLabel: OptionToLabel<TOption>
  selectedOptions: TOption[]
  maxOptions?: number
  isComboboxOpen: boolean
  hasLargePills?: boolean
  onInputFocus?: () => void
  onInputBlur?: () => void
} & Pick<
  UseMultipleSelectionReturnValue<TOption>,
  'removeSelectedItem' | 'getSelectedItemProps' | 'getDropdownProps'
> &
  Pick<UseComboboxReturnValue<TOption>, 'getInputProps'>

export function AutocompleteInput<TOption>({
  isComboboxOpen,
  placeholder,
  optionToLabel,
  selectedOptions,
  maxOptions,
  removeSelectedItem,
  getSelectedItemProps,
  getInputProps,
  getDropdownProps,
  onInputFocus,
  onInputBlur,
  hasLargePills = false,
}: AutocompleteInputProps<TOption>) {
  const isMaxOptions = Boolean(maxOptions && selectedOptions.length === maxOptions)
  const InputWrapper = isMaxOptions ? VisuallyHidden : Fragment
  const inputRef = useRef<HTMLDivElement>(null)
  return (
    <AutocompleteInputGrid ref={inputRef}>
      <AutocompleteSelectedItems
        getSelectedItemProps={getSelectedItemProps}
        optionToLabel={optionToLabel}
        selectedOptions={selectedOptions}
        removeSelectedItem={removeSelectedItem}
        hasLargePills={hasLargePills}
        inputRef={inputRef}
      />
      {
        <InputWrapper>
          <AutocompleteTextInput
            disabled={isMaxOptions}
            placeholder={placeholder}
            {...getInputProps(
              getDropdownProps({
                preventKeyAction: isComboboxOpen,
                onFocus: onInputFocus,
                onBlur: onInputBlur,
              }),
            )}
          />
        </InputWrapper>
      }
    </AutocompleteInputGrid>
  )
}
