import type { UseComboboxReturnValue } from 'downshift'
import { styled } from '@qasa/ui/web'

import { AutocompleteLoader, AutocompleteNoResults } from '../autocomplete.shared'
import { AutocompleteOption } from '../autocomplete-option.web'

import type { OptionToSublabel, OptionToLabel, OptionToCountryLabel } from './autocomplete'

const AutocompleteDropdown = styled('ul')<{ horizontalOffset: number }>(({ theme, horizontalOffset }) => ({
  paddingInline: theme.spacing['4x'],
  paddingBlock: theme.spacing['6x'],
  display: 'flex',
  flexDirection: 'column',
  minHeight: theme.sizes['12x'],
  maxHeight: 'min(394px, 50vh)',
  position: 'absolute',
  top: `calc(100% + ${theme.spacing['2x']})`,
  left: horizontalOffset,
  right: horizontalOffset,
  background: theme.colors.bg.default,
  borderRadius: theme.radii.lg,
  border: '1px solid',
  borderColor: theme.colors.border.subtle,
  boxShadow: theme.shadows.lg,
  overflowY: 'auto',
  zIndex: theme.zIndices.dropdown,
}))

type AutocompleteListboxProps<TOption> = {
  isOpen: boolean
  isLoading: boolean
  options: TOption[]
  optionToLabel: OptionToLabel<TOption>
  optionToSublabel?: OptionToSublabel<TOption>
  optionToCountryLabel?: OptionToCountryLabel<TOption>
  inputValue: string
  highlightedIndex: number
  horizontalOffset?: number
} & Pick<UseComboboxReturnValue<TOption>, 'getMenuProps' | 'getItemProps'>

export function AutocompleteListbox<TOption>({
  isLoading,
  optionToLabel,
  options,
  optionToSublabel,
  optionToCountryLabel,
  inputValue,
  isOpen,
  highlightedIndex,
  getItemProps,
  getMenuProps,
  horizontalOffset = 0,
}: AutocompleteListboxProps<TOption>) {
  if (isLoading) {
    return (
      <AutocompleteDropdown {...getMenuProps()} horizontalOffset={horizontalOffset}>
        <AutocompleteLoader />
      </AutocompleteDropdown>
    )
  }

  const hasNoResults = !options.length && inputValue

  if (hasNoResults) {
    return (
      <AutocompleteDropdown {...getMenuProps()} horizontalOffset={horizontalOffset}>
        <AutocompleteNoResults />
      </AutocompleteDropdown>
    )
  }

  return (
    <AutocompleteDropdown {...getMenuProps()} horizontalOffset={horizontalOffset}>
      {isOpen &&
        options.map((option, index) => {
          const isHighlighted = index === highlightedIndex
          return (
            <AutocompleteOption
              {...{ isHighlighted }}
              key={`${option}${index}`}
              {...getItemProps({ item: option, index })}
              label={optionToLabel(option)}
              sublabel={optionToSublabel ? optionToSublabel(option) : null}
              countryLabel={optionToCountryLabel ? optionToCountryLabel(option) : null}
            />
          )
        })}
    </AutocompleteDropdown>
  )
}
