import { forwardRef } from 'react'
import { Stack, Label, MapPinIcon } from '@qasa/qds-ui'
import { styled } from '@qasa/ui/web'

import { useRegionContext } from '../../contexts/region'
import { useAppTranslation } from '../../contexts/i18next'

import type { AutocompleteOptionProps, OptionWrapperProps } from './autocomplete.shared'

const OptionWrapper = styled('li')<OptionWrapperProps>(({ isHighlighted, theme }) => ({
  appearance: 'none',
  paddingLeft: theme.spacing['4x'],
  height: theme.sizes['12x'],
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  background: isHighlighted ? theme.colors.core.gray20 : 'inherit',
  borderRadius: theme.radii.full,
  userSelect: 'none',
  cursor: 'pointer',
}))

export const AutocompleteOption = forwardRef<HTMLLIElement, AutocompleteOptionProps>((props, ref) => {
  const { t } = useAppTranslation('commons')
  const { region } = useRegionContext()
  const { isHighlighted = false, label, sublabel, countryLabel, onPress, ...restProps } = props
  const formattedSubLabel = sublabel ? `, ${sublabel}` : ''

  return (
    <OptionWrapper ref={ref} isHighlighted={isHighlighted} onClick={onPress} {...restProps}>
      <Stack direction="row" gap="2x" alignItems="center">
        <Stack direction="row" gap="4x">
          <MapPinIcon size={16} style={{ flexShrink: 0 }} />
          <Label as="span" style={{ cursor: 'inherit' }}>
            {label + formattedSubLabel}
          </Label>
        </Stack>
        {region === 'international' && (
          <Label as="span" color="subtle">
            {t(`countries.${countryLabel}`)}
          </Label>
        )}
      </Stack>
    </OptionWrapper>
  )
})
